import { StocksComponent } from './PopUpModal/stocks/stocks.component';
import { ToastrService } from 'ngx-toastr';
import { RawMaterialsPartComponent } from './../../Shared/raw-materials-part/raw-materials-part.component';
import { DetailsComponent } from './PopUpModal/details/details.component';
import { UpdatePartComponent } from './PopUpModal/update-part/update-part.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireDatabase } from '@angular/fire/database';
import { PartDB_controller } from '../../Services/DB_Controller/PartDB_controller';
import { Part } from '../../Services/Object_Classes/Part/Part';
import { MatPaginator, PageEvent, Sort } from '@angular/material';
import { AddPartComponent } from './PopUpModal/add-part/add-part.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { finalize } from 'rxjs/operators';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.css']
})
export class PartsComponent implements OnInit {

  partlist = [];
  //Pagination
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: '',
    direction: ''
  };
  ClonePartList= [];
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;


  //Pagination warehouse
  lengthWarehouse = 0;
  pageSizeWarehouse = 10;
  pageIndexWarehouse = 0;
  offsetWarehouse = this.pageSizeWarehouse * this.pageIndexWarehouse;
  searchWarehouse: string;
  sorteduWarehouse = {
    active: '',
    direction: ''
  };
  ClonePartListWarehouse = [];
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginatorWarehouse: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginatorWarehouse: MatPaginator;

  email;
  constructor(
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private toast: ToastrService,
    private excelHelper: ExcelHelperService,
    private dateFormat: DateFormatService,
    private angularFireAuth: AngularFireAuth,

  ) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
  }

  ngOnInit() {
    this.setup();
  }
  refresh() {
    this.setup();
  }

  setup() {
    this.spinner.show();
    this.partlist = [];
    this.firestore.collection("Parts", ref=> ref.where('isDeleted', '==', false).orderBy('itemCode','asc')).get().forEach(part=>{
      if(!part.empty){
        part.forEach(partt=>{
          const data = {
            id: partt.id,
            ...partt.data(),
            size: parseFloat(partt.data().width) * parseFloat(partt.data().height),
            length: parseFloat(partt.data().length),
            pieces: parseFloat(partt.data().pieces),
            cycleTime: parseFloat(partt.data().cycleTime),
          }
          this.partlist.push(data);
        })
        this.length = this.partlist.length;
        this.lengthWarehouse = this.partlist.length;
        this.ClonePartList = this.partlist.slice();
        this.ClonePartListWarehouse = this.partlist.slice();
        this.limitList();
        this.limitListWarehouse();
        this.spinner.hide();
      }
      else{
        this.spinner.hide();
      }
    })
   
  }

  addPart(): void {
    const dialogRefaddPart = this.dialog.open(AddPartComponent, {
      width: '90%',
      height: '90%'
    });

    dialogRefaddPart.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        const newPart = result;
        newPart.createdDate = new Date();
        newPart.createdBy = this.email;
        newPart.updatedDate = new Date();
        newPart.updatedBy = this.email;
        newPart.isDeleted = false;
        newPart.pieces = 0;
        newPart.scheduleNo = 0;

        this.spinner.show();
     
        this.firestore.collection("Parts").add(newPart).then(() => {
          this.toast.success(newPart.itemCode + " has been added successfully!", "Added Successfully")
        }).catch(error => {
          this.toast.error(newPart.itemCode + " has been added failed! Please try to contact administrator.", "Added Failed")
        }).finally(async () => {
          this.setup();

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            date: new Date(),
            content: JSON.stringify(newPart),
            itemCode: newPart.itemCode,
            addedBy: this.email,
          };
          await this.firestore.collection('AddPartLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('AddPartLog').doc(dateFormat).collection('Part').add(info);

        });
      }
    });
  }

  viewPartDetail(part): void {
    const dialogRefupdatePart = this.dialog.open(AddPartComponent, {
      data: part,
      width: '90%',
      height: '90%',
    });

    dialogRefupdatePart.afterClosed().subscribe(async result => {
      if (typeof result !== 'undefined') {
        const updatePart = result;
        updatePart.updatedDate = new Date();
        updatePart.updatedBy = this.email;
        this.spinner.show();
        this.firestore.collection("Parts").doc(part.id).update(updatePart).then(() => {
          this.toast.success(updatePart.partName + " has been updated successfully!", "Updated Successfully")
        }).catch(error => {
          this.toast.error(updatePart.partName + " has been updated failed! Please try to contact administrator.", "Updated Failed")
        }).finally(async () => {
          this.setup();
          const info = {
            date: new Date(),
            content: JSON.stringify(updatePart),
            updatedBy: this.email,
            itemCode: updatePart.itemCode
          };
          await this.firestore.collection("Parts").doc(part.id).collection("Logs").add(info);
        });
      }
    });
  }


  deletePart(part): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Do you really want to delete this part? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.firestore.collection("Parts").doc(part.id).update({isDeleted: true}).then(()=>{
          this.toast.success(part.itemCode + " has been deleted successfully!", "Deleted Successfully")
        }).catch(error=>{
          this.toast.error(part.itemCode + " has been deleted failed! Please try to contact administrator.", "Deleted Failed")
        }).finally(async ()=>{
          this.setup();
          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            date: new Date(),
            content:JSON.stringify(part),
            deletedBy: this.email,
            itemCode: part.itemCode
          };
          await this.firestore.collection('DeletePartLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('DeletePartLog').doc(dateFormat).collection('Part').add(info);

        });
      }
    })
  }


  DynamicSearchPartNumber(partNum: string): void {
    this.search = partNum;
    this.ClonePartList = this.partlist.filter(u =>
      String(u.itemCode).toLowerCase().includes(this.search.toLowerCase())
      || String(u.clientName).toLowerCase().includes(this.search.toLowerCase())
      || String(u.species).toLowerCase().includes(this.search.toLowerCase())
      || String(u.grade).toLowerCase().includes(this.search.toLowerCase())
      || String(u.supplierName).toLowerCase().includes(this.search.toLowerCase())
      || String(u.size).toLowerCase().includes(this.search.toLowerCase())
      || String(u.length).toLowerCase().includes(this.search.toLowerCase())
      || String(u.pieces).toLowerCase().includes(this.search.toLowerCase())
      || String(u.tonnage).toLowerCase().includes(this.search.toLowerCase())
      || String(u.cycleTime).toLowerCase().includes(this.search.toLowerCase())
    );
    this.length = this.ClonePartList.length;
    this.sortPartList();
    this.limitList();
    this.topPaginator.firstPage();
    this.bottomPaginator.firstPage();
  }
  DS() {
    this.ClonePartList = this.partlist.filter(u =>
      String(u.itemCode).toLowerCase().includes(this.search.toLowerCase())
      || String(u.clientName).toLowerCase().includes(this.search.toLowerCase())
      || String(u.species).toLowerCase().includes(this.search.toLowerCase())
      || String(u.grade).toLowerCase().includes(this.search.toLowerCase())
      || String(u.supplierName).toLowerCase().includes(this.search.toLowerCase())
      || String(u.size).toLowerCase().includes(this.search.toLowerCase())
      || String(u.length).toLowerCase().includes(this.search.toLowerCase())
      || String(u.pieces).toLowerCase().includes(this.search.toLowerCase())
      || String(u.tonnage).toLowerCase().includes(this.search.toLowerCase())
      || String(u.cycleTime).toLowerCase().includes(this.search.toLowerCase())
    );
    this.length = this.ClonePartList.length;
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    if (this.search) {
      this.DS();
    } else {
      this.ClonePartList = this.partlist.slice();
    }
    this.sortPartList();
    this.limitList();
  }
  limitList() {
    this.ClonePartList = this.ClonePartList.slice(this.offset, (this.offset + this.pageSize));
  }

  sortPartList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.ClonePartList = this.ClonePartList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'itemCode': return this.compare(a.itemCode, b.itemCode, isAsc);
        case 'clientName': return this.compare(a.clientName, b.clientName, isAsc);
        case 'species': return this.compare(a.species, b.species, isAsc);
        case 'grade': return this.compare(a.grade, b.grade, isAsc);
        case 'supplierName': return this.compare(a.supplierName, b.supplierName, isAsc);
        case 'size': return this.compare(a.size, b.size, isAsc);
        case 'length': return this.compare(a.length, b.length, isAsc);
        case 'pieces': return this.compare(a.pieces, b.pieces, isAsc);
        case 'cycleTime': return this.compare(a.cycleTime, b.cycleTime, isAsc);
        default: return 0;
      }
    });
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.ClonePartList = this.partlist.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.ClonePartList = this.partlist.slice();
      this.limitList();
      return;
    }

    this.ClonePartList = this.ClonePartList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'itemCode': return this.compare(a.itemCode, b.itemCode, isAsc);
        case 'clientName': return this.compare(a.clientName, b.clientName, isAsc);
        case 'species': return this.compare(a.species, b.species, isAsc);
        case 'grade': return this.compare(a.grade, b.grade, isAsc);
        case 'supplierName': return this.compare(a.supplierName, b.supplierName, isAsc);
        case 'size': return this.compare(a.size, b.size, isAsc);
        case 'length': return this.compare(a.length, b.length, isAsc);
        case 'pieces': return this.compare(a.pieces, b.pieces, isAsc);
        case 'cycleTime': return this.compare(a.cycleTime, b.cycleTime, isAsc);
        default: return 0;
      }
    });
    this.limitList();
  }


  DynamicSearchPartNumberWarehouse(partNum: string): void {
    this.searchWarehouse = partNum;
    this.ClonePartListWarehouse = this.partlist.filter(u =>
      String(u.itemCode).toLowerCase().includes(this.search.toLowerCase())
    );
    this.lengthWarehouse = this.ClonePartListWarehouse.length;
    this.sortPartListWarehouse();
    this.limitListWarehouse();
    this.topPaginatorWarehouse.firstPage();
    this.bottomPaginatorWarehouse.firstPage();
  }
  DSWarehouse() {
    this.ClonePartListWarehouse = this.partlist.filter(u =>
      String(u.itemCode).toLowerCase().includes(this.search.toLowerCase())
    );
    this.lengthWarehouse = this.ClonePartListWarehouse.length;
  }

  paginatorWarehouse(pageEvent: PageEvent) {
    this.pageSizeWarehouse = pageEvent.pageSize;
    this.pageIndexWarehouse = pageEvent.pageIndex;
    this.offsetWarehouse = this.pageSizeWarehouse * this.pageIndexWarehouse;
    if (this.topPaginatorWarehouse.pageIndex < this.pageIndexWarehouse) {
      this.topPaginatorWarehouse.nextPage();
    } else if (this.topPaginatorWarehouse.pageIndex > this.pageIndexWarehouse) {
      this.topPaginatorWarehouse.previousPage();
    }
    if (this.bottomPaginatorWarehouse.pageIndex < this.pageIndexWarehouse) {
      this.bottomPaginatorWarehouse.nextPage();
    } else if (this.bottomPaginatorWarehouse.pageIndex > this.pageIndexWarehouse) {
      this.bottomPaginatorWarehouse.previousPage();
    }
    if (this.searchWarehouse) {
      this.DSWarehouse();
    } else {
      this.ClonePartListWarehouse = this.partlist.slice();
    }
    this.sortPartListWarehouse();
    this.limitListWarehouse();
  }
  limitListWarehouse() {
    this.ClonePartListWarehouse = this.ClonePartListWarehouse.slice(this.offsetWarehouse, (this.offsetWarehouse + this.pageSizeWarehouse));
  }

  sortPartListWarehouse() {
    if (!this.sorteduWarehouse.active || this.sorteduWarehouse.direction === '') {
      return;
    }
    this.ClonePartListWarehouse = this.ClonePartListWarehouse.sort((a, b) => {
      const isAsc = this.sorteduWarehouse.direction === 'asc';
      switch (this.sorteduWarehouse.active) {
        case 'itemCode': return this.compare(a.itemCode, b.itemCode, isAsc);
        case 'stock': return this.compare(a.pieces, b.pieces, isAsc);
        case 'reserved': return this.compare(a.reservedStock, b.reservedStock, isAsc);
        case 'location': return this.compare(a.location, b.location, isAsc);
        default: return 0;
      }
    });
  }

  sortDataWarehouse(sort: Sort) {
    this.sorteduWarehouse = sort;
    this.ClonePartListWarehouse = this.partlist.slice();
    if (this.searchWarehouse) {
      this.DSWarehouse();
    }
    if (!sort.active || sort.direction === '' && !this.searchWarehouse) {
      this.ClonePartListWarehouse = this.partlist.slice();
      this.limitListWarehouse();
      return;
    }

    this.ClonePartListWarehouse = this.ClonePartListWarehouse.sort((a, b) => {
      const isAsc = this.sorteduWarehouse.direction === 'asc';
      switch (this.sorteduWarehouse.active) {
        case 'itemCode': return this.compare(a.itemCode, b.itemCode, isAsc);
        case 'stock': return this.compare(a.stockQuantity, b.stockQuantity, isAsc);
        case 'reserved': return this.compare(a.reservedStock, b.reservedStock, isAsc);
        case 'location': return this.compare(a.location, b.location, isAsc);
        default: return 0;
      }
    });
    this.limitListWarehouse();
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  exportToExcel() {
    // const exportInformation = [];
    // this.spinner.show();
    // for (const part of this.partlist) {
    //   const material = part.Raw_Material.find(r => r.Raw_Type === 'Raw Material');
    //   const pigment = part.Raw_Material.find(r => r.Raw_Type === 'Pigment');
    //   const batch = part.Raw_Material.find(r => r.Raw_Type === 'Material Batch');

    //   const info = {
    //     "Part No": part.partNo,
    //     "Part Name": part.partName,
    //     "Part Weight": part.Part_Weight || '-',
    //     "Runner Weight": part.Part_Runner_Weight || '-',
    //     "Model": part.Model || 'N/A',
    //     "No of Cavity": part.Number_Cavity || 'N/A',
    //     "Customer Licence": part.Customer_License || 'N/A',
    //     "Duty Export": part.Duty_Export || 'N/A',
    //     "Project Start Date": part.Project_Start_Date || 'N/A',
    //     "Tarif Code": part.Traffic_Code || 'N/A',
    //     "Tarif Code Name": part.Traffic_Code_Name || 'N/A',
    //     "Customer Name": part.Customer_Name || 'N/A',
    //     "Purchase Order": part.PO_No || 'N/A',
    //     "Part Price List Mold": part.Mould_Price || '-',
    //     "Part Price List Customer": part.Part_Price || '-',
    //     "MOQ Remarks": part.Remarks || 'N/A',
    //     "Mold Family Info": part.Mould_Family || 'N/A',
    //     "Mold Information": part.Mould_Info || 'N/A',
    //     "Mold Material": part.Mould_Material || 'N/A',
    //     "Mold Maker": part.Mould_Maker_Name || 'N/A',
    //     "Mold Maker Phone": part.Mould_Rack_Location || 'N/A',
    //     "Mold Reference No": part.Mould_Reference_No || 'N/A',
    //     "Mold Size": part.Mould_Size || 'N/A',
    //     "Cycle Time": part.Cycle_Time || '-',
    //     "Machine Tonnage": part.Machine_Tonnage || '-',
    //     "Machine No": part.Available_Machine || '-',
    //     "Carton Box": part.CartonBox.Material_Name || 'N/A',
    //     "Quantity In Box": part.Box_Quantity || 0,
    //     "Poly Bag/ Inner Box": part.PolyBag.Material_Name || part.InnerBox.Material_Name || "N/A",
    //     "Quantity In Poly Bag/ Inner Box": part.Polybag_Quantity || part.Quantity_InnerBox || 0,
    //     "Supplier Carton Packing": part.Supplier1,
    //     "Supplier Poly Bag/ Inner Box": part.Supplier6 || "N/A",
    //     "Raw Material": material ? material.Material_Name : '-',
    //     "Material Ratio": material ? material.Mat_Ratio : '-',
    //     "Customer Material": material ? material.Customer_Material : '-',
    //     "SPEN Material": material ? material.SPEN_Material : '-',
    //     "Customer Ral": material ? material.Customer_RAL : '-',
    //     "Supplier 3": material ? material.Supplier : "N/A",
    //     "Pigment/Colour": pigment ? pigment.Material_Name : 'N/A',
    //     "Pigment/Colour Ratio": pigment ? pigment.Mat_Ratio : "N/A",
    //     "Supplier 3(Pigment)": pigment ? pigment.Supplier : 'N/A',
    //     "Master Batch": batch ? batch.Material_Name : 'N/A',
    //     "Master Batch Ratio": batch ? batch.Mat_Ratio : "N/A",
    //     "Supplier 3(Batch)": batch ? batch.Supplier : 'N/A',
    //     "Secondary Process 1": part.Sec_Pro_1,
    //     "Cycle Time (Sec process 1)": part.Sec_Pro_1_Cycle_Time,
    //     "Usage 1": part.Usage1 || 'N/A',
    //     "Supplier 4": part.Supplier4 || 'N/A',
    //     "Secondary Process 2": part.Sec_Pro_2 || 'N/A',
    //     "Cycle Time (Sec process 2)": part.Sec_Pro_2_Cycle_Time || 'N/A',
    //     "Usage 2": part.Usage2 || 'N/A',
    //     "Supplier 5": part.Supplier5 || 'N/A',
    //   }
    //   exportInformation.push(info)
    // }

    // this.excelHelper.exportAsExcelFile(exportInformation, 'part' + new Date().getTime());
    // this.spinner.hide();

  }

  exportToExcelWarehouse() {
    // const exportInformation = [];
    // this.spinner.show();
    // for (const part of this.partlist) {
    //   const info = {
    //     "Part No": part.partNo,
    //     "Part Name": part.partName,
    //     "Stock Quantity": part.Stock_Quantity || 0,
    //     "Reserved Stock": part.Reserved_Stock || 0,
    //     "Location": part.rackingNumber.slice(0, -1) || "N/A",

    //   }
    //   exportInformation.push(info)
    // }

    // this.excelHelper.exportAsExcelFile(exportInformation, 'partWarehouse' + new Date().getTime());
    // this.spinner.hide();

  }

}

