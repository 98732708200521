import { NgxSpinnerService } from 'ngx-spinner';
import { ViewRawMaterialsComponent } from './../../../Shared/view-raw-materials/view-raw-materials.component';
import { ConfirmationDialogComponent } from './../../../Shared/confirmation-dialog/confirmation-dialog.component';
import { ScheduleComponent } from './../../schedule/schedule.component';
import { SelectMachineSlotComponent } from './select-machine-slot/select-machine-slot.component';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, ThemePalette } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MachineDB_controller } from '../../../Services/DB_Controller/MachineDB_controller';
import { PartDB_controller } from '../../../Services/DB_Controller/PartDB_controller';
import { Part } from '../../../Services/Object_Classes/Part/Part';
import { ScheduleTracker, Machine } from '../../../Services/Object_Classes/Machine/Machine';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { AngularFirestore } from '@angular/fire/firestore';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.css']
})
export class AddEventComponent implements OnInit {
  poNo: any;
  totalPart: any;
  itemCode: any;
  quantity: any;
  part: Part = new Part();
  machine: Machine = new Machine();
  part_controller: PartDB_controller = new PartDB_controller(this.db, this.storage,this.firestore);
  machine_controller: MachineDB_controller = new MachineDB_controller(this.db);
  po_controller: PODB_controller = new PODB_controller(this.db);
  machineList: Machine[] = [];
  availableMachineList: any[] = [];
  selectedMachineList: any[] = [];
  partlist = [];
  matSelectPart = [];
  SelectPart2 = []
  SelectPart1 = []
  clone = [];
  runningScheduleList = [];
  estimateTotalTime = [];
  timeslots = [];
  dateslots = [];
  addForm: FormGroup;
  startDate = [];
  endDate = [];
  searchPart: any;
  check = false;
  email: string;
  formvalid = [];
  @ViewChild('picker', { static: true }) picker: any;

  public date: moment.Moment;
  public minDate: Date;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  });

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];
  private dateFormat = new DateFormatService();
  fixed = false;
  fixedMachineNo: any;

  constructor(
    private dialogRef: MatDialogRef<AddEventComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
  ) {
    if(data){
      this.fixed = true;
      this.fixedMachineNo = data;
    }
    this.addForm = this.fb.group({
      parts: this.fb.array([]),
    });
    this.minDate = new Date();
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    })
    this.addParts();

  }
  existingName = false

  async nameValidation() {
    const name = this.addForm.get('name').value.trim();
    this.firestore.collection("PurchaseOrders", ref => ref.where('jobOrderName', '==', name)).get().forEach(e => {
      if (e.size > 0) {
        this.existingName = true;
      } else {
        this.existingName = false;
      }
    })
  }

  mlist = [];
  ngOnInit() {
    this.spinner.show();

    this.firestore.collection('Machines').get().forEach(machine=>{
      if(machine.size > 0){
        machine.forEach(m=>{  
          const machine = { machineNumber: m.id, ...m.data(), schedules: [] };
          this.mlist.push(machine);
          this.firestore.collection('Machines').doc(m.id).collection('Schedule_Current').get().forEach(schedules=>{
            schedules.forEach(s=>{
              var schedule = {id:s.id,...s.data()};
              machine.schedules.push(schedule);
            })
            this.firestore.collection('Machines').doc(m.id).collection('Schedule_Queue', ref=>ref.orderBy('endDate','asc')).get().forEach(schedules=>{
              schedules.forEach(s=>{
                var schedule = {id:s.id,...s.data()};
                machine.schedules.push(schedule);
              })
            })
          })
          
          this.mlist = this.mlist.sort((a, b) => {
            return (Number(a.machineNumber.match(/(\d+)/g)[0]) - Number((b.machineNumber.match(/(\d+)/g)[0])));
          })
        })
      }
    })

    this.firestore.collection("Parts", ref=> ref.where('isDeleted', '==', false)).get().forEach(part=>{
      if(!part.empty){
        part.forEach(partt=>{
          const data = {
            id: partt.id,
            ...partt.data()
          }
          this.matSelectPart.push(data);
          this.clone = this.matSelectPart.slice();
        })
        this.spinner.hide();
      }
    });

    
   
  }


  parts(): FormArray {
    return this.addForm.get('parts') as FormArray;
  }

  newParts(): FormGroup {

    const partControl = new FormControl();
   
    const partfilterControl = new FormControl();

    const pos = new FormControl();
    pos.disable();
    const reference = new FormControl();
    reference.disable();

    const index = this.parts().length;
    partfilterControl.valueChanges.subscribe((value) => {
      this.findPart(partfilterControl);
    });
   
    partControl.valueChanges.subscribe(() => {
      if (partControl.value) {
        pos.enable();
        reference.enable();
        this.searchPart = partControl.value;
        this.check = true;
      }else{
        pos.setValue("")
        pos.disable();
        reference.setValue("")
        reference.disable();
      }
    });


    return this.fb.group({
      partControl,
      partfilterControl,
      itemCode: '',
      quantity: '',
      availableStock: '',
      raw: '',
      completeTime: '',
      availableMachineList: '',
      dateControl: new Date(),
      poQuantity: '',
      pos,
      reference,
      flag:false,
    });
  }

  findPart(part) {
    if (!this.clone) { return; }
    const search = part.value;
    this.matSelectPart = this.clone.filter(p => p.itemCode.toLowerCase().includes(search.toLowerCase()));
  }


  addParts() {
    this.parts().push(this.newParts());
    this.availableMachineList.push(null);
    this.selectedMachineList.push(null);
    this.partlist.push(null);
    this.runningScheduleList.push(null);
    this.estimateTotalTime.push(null);
    this.startDate.push(null);
    this.endDate.push(null);
    this.formvalid.push(true);
    this.SelectPart1.push([]);
    this.SelectPart2.push([]);
    const info = {
      part: "",
      index: -1,
    }
    this.choosingPart.push(info);

  }

  removeParts(i: number) {
    this.parts().removeAt(i);
    this.availableMachineList.splice(i, 1);
    this.partlist.splice(i, 1);
    this.runningScheduleList.splice(i, 1);
    this.estimateTotalTime.splice(i, 1);
    this.startDate.splice(i, 1);
    this.endDate.splice(i, 1);
    this.formvalid.splice(i, 1);
    this.SelectPart2.splice(i, 2);
    this.SelectPart1.splice(i, 2);
    this.choosingPart.splice(i,2);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
   
    if (this.dateFormat.checkCrushDate(this.startDate, this.endDate, this.selectedMachineList)) {
      this.toast.error('There a crush between new added item code in machine scheduling', 'Please check');
      return;
    };
    const addPOModel = {
      POName: '',
      parts: []
    };
    const parts = this.addForm.get('parts').value;

    parts.forEach(async (element, index) => {
      if (!this.selectedMachineList[index]) {
        this.toast.error('Item information(' + (index + 1) + ') not completed!', 'Please fill in');
        return;
      }

      const date = this.startDate[index];
      const endDate = this.endDate[index];
      if (!element.poQuantity )
        element.poQuantity = '0';
      const info = {
        id:this.partlist[index].id,
        machineChosen: this.selectedMachineList[index].machineNumber,
        completeTime: this.estimateTotalTime[index],
        startTime: date,
        endTime: endDate,
        itemCode: element.partControl,
        clientName: this.partlist[index].clientName,
        width: this.partlist[index].width,
        height: this.partlist[index].height,
        length: this.partlist[index].length,
        cycleTime: this.partlist[index].cycleTime,
        stockCode:element.pos,
        reference:element.reference,
        productionQuantity: element.quantity,
        poQuantity: element.poQuantity,
        scheduleID: uuidv4(),
      };

  
      if (!info.machineChosen || !info.completeTime || !info.itemCode  || !info.productionQuantity || !this.formvalid[index]) {
        this.toast.error('Item information(' + (index + 1) + ') not completed!', 'Please fill in');
        return;
      }

      addPOModel.parts.push(info);
    });


    if (addPOModel.parts.length === 0) {
      this.toast.error('Please add at least one part', 'Add part');
      return;
    }
   
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm add this Job?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show();
        await this.addPO(addPOModel);
        this.spinner.hide();

        this.dialogRef.close(addPOModel);
        this.toast.success('Schedule Job successful');

      }
    });

  }

  async addPO(addPOModel){
    const poUUid = uuidv4();

    await this.firestore.collection("Parts").doc(addPOModel.parts[0].id).get().forEach(async item => {
      if(item.exists){
        let scheduleNo = item.data().scheduleNo?item.data().scheduleNo +1: 1;
        let ID = ('000' + scheduleNo).slice(-3);
       
        ID = `${item.data().itemCode}-${ID}`;
        addPOModel.POName = ID;

        for (const data of addPOModel.parts) {
          let updates = {};
          updates['counter'] = 0;
          updates['productionQuantity'] = data.productionQuantity;
          updates['poQuantity'] = data.poQuantity;
          updates['code'] = '-';
          updates['poNo'] = poUUid;
          updates['jobOrderNo'] = addPOModel.POName;
          updates['itemCode'] = data.itemCode;
          updates['status'] = 'Waiting';
          updates['startDate'] = data.startTime;
          updates['endDate'] = data.endTime;
          updates['clientName'] = data.clientName;
          updates['width'] = data.width;
          updates['height'] = data.height;
          updates['length'] = data.length;
          updates['downStart'] = 0;
          updates['downEnd'] = 0;
          updates['totalDownTime'] = 0;
          updates['availability'] = 0;
          updates['performance'] = 0;
          updates['effectiveness'] = 0;
          updates['stockCode'] = data.stockCode;
          updates['reference'] = data.reference;
          updates['createdDate'] = new Date();
          updates['updatedDate'] = new Date();
          updates['createdBy'] = this.email;
          updates['updatedBy'] = this.email;
          updates['cycleTime'] = data.cycleTime;
    
          this.firestore.collection("Machines").doc(data.machineChosen).collection("Schedule_Queue").doc(data.scheduleID).set(updates)
        }
    
        let newPO = {};
    
        newPO['createdDate'] = new Date();
        newPO['updatedDate'] = new Date();
        newPO['createdBy'] = this.email;
        newPO['updatedBy'] = this.email;
        newPO['poName'] = addPOModel.POName;
        newPO['jobOrderNo'] = addPOModel.POName;
    
        await this.firestore.collection("PurchaseOrders").doc(poUUid).set(newPO);
    
        for (const data of addPOModel.parts) {
          const info = {}
          info['itemCode'] = data.itemCode;
          info['productionQuantity'] = data.productionQuantity;
          info['poQuantity'] = data.poQuantity;
          info['status'] = "Waiting";
          info['startDate'] = data.startTime;
          info['endDate'] = data.endTime;
          info['completedDate'] = "";
          info['addedToPacking'] = false;
          info['machineNo'] = data.machineChosen;
          info['stockCode'] = data.stockCode;
          info['reference'] = data.reference;
          info['clientName'] = data.clientName;
          info['width'] = data.width;
          info['height'] = data.height;
          info['length'] = data.length;
          info['cycleTime'] = data.cycleTime;
          await this.firestore.collection("PurchaseOrders").doc(poUUid).collection("Parts").doc(data.scheduleID).set(info);
        }
    
        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          date: new Date(),
          content:JSON.stringify(addPOModel),
          PoNo:  addPOModel.POName,
          addedBy: this.email
        };
        await this.firestore.collection('AddPOLog').doc(dateFormat).set({ Date: new Date() });
        await this.firestore.collection('AddPOLog').doc(dateFormat).collection('PO').add(info);

        await this.firestore.collection('Parts').doc(addPOModel.parts[0].id).update({ scheduleNo: scheduleNo});

      }else{
        this.toast.error('Schedule Job failed, please contact adminstrator');
        
      }
    })

  }

  getRandomString(length) {
    let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
  choosingPart = [];

  getMachineForPart(itemCode: any, formindex) {
    this.spinner.show();
    const samePart = this.choosingPart.find(c => c.part === itemCode && c.index !== formindex);
    if (samePart) {
      this.toast.warning("Same part number has been ordered, please choose others", "Warning");
      this.partlist[formindex] = null;
      this.availableMachineList[formindex]=null;
      this.spinner.hide();
      return;
    }

   
    if (itemCode) {
      const result = this.clone.find(e=>e.itemCode === itemCode)
      if(result){
        this.partlist[formindex] = result;
        let previous, current, decide;
        const info = {
          part: itemCode,
          index: formindex,
        }
        this.choosingPart[formindex] = info;

        for (const machine of this.mlist) {
          if(machine.schedules.length === 0){
            decide = machine;
            break;
          }
          else {
            machine.startDate = new Date(machine.schedules[0].startDate.toDate());
            machine.endDate = new Date(machine.schedules[0].endDate.toDate());
            if (!previous) {
              previous = machine;
              decide = previous;
            } else {
              current = machine;
              if (new Date(current.schedules[0].endDate).getTime() <
                new Date(previous.schedules[0].endDate).getTime()) {
                decide = current;
              } else {
                decide = previous;
              }
              previous = current;
            }
          }
        }
       
        this.availableMachineList[formindex] = this.mlist;
        if (decide) {
          this.selectedMachineList[formindex] = decide;
          if(decide.schedules.length > 0){
            this.startDate[formindex] = new Date(decide.schedules[0].endDate.toDate());
            if(this.startDate[formindex].getTime() < new Date().getTime()){
              this.startDate[formindex] =new Date();
            }
            this.startDate[formindex].setHours(this.startDate[formindex].getHours() + 1);
          }else{
            this.startDate[formindex] =new Date();
            this.startDate[formindex].setHours(this.startDate[formindex].getHours() + 1);
          }
         
        } else {
          this.startDate[formindex] = new Date();
          this.startDate[formindex].setHours(this.startDate[formindex].getHours() + 1);
        }
      }
    }

    this.spinner.hide();

  }


  estimateTime(partQuantity: any, formindex) {
    this.estimateTotalTime[formindex] = (9.8425 * (partQuantity))/ 3600;

    this.endDate[formindex] = new Date(this.startDate[formindex].getTime()
      + this.estimateTotalTime[formindex] * 60 * 60000);

    const mac = this.mlist.find(e=>e.machineNumber === this.selectedMachineList[formindex].machineNumber);
    if(mac){
      if (this.dateFormat.validateSchedulingMachine(this.startDate[formindex], this.endDate[formindex], mac)) {
        this.toast.error('Crush with original schedule', 'Please select other time');
        this.formvalid[formindex] = false;
        return;
      }
      else {
        this.formvalid[formindex] = true;
      }
    }
  }


  selectMachine(formindex, machineindex) {
    this.selectedMachineList[formindex] = this.availableMachineList[formindex][machineindex];
    let endDate; 
    if(this.selectedMachineList[formindex].schedules.length > 0){
      endDate = new Date(this.selectedMachineList[formindex].schedules[0].endDate.toDate());
      this.startDate[formindex] = new Date(this.selectedMachineList[formindex].schedules[0].endDate.toDate());
    }else{
      endDate = new Date();
      this.startDate[formindex] = new Date();
    }

    if(endDate.getTime() < new Date().getTime()){
      endDate = new Date();
      this.startDate[formindex] =new Date();
    }
    endDate.setHours(endDate.getHours() +1);
    this.startDate[formindex].setHours(this.startDate[formindex].getHours() + 1);

    if(this.estimateTotalTime[formindex]){
      this.endDate[formindex] = new Date(
        endDate.getTime()
      + this.estimateTotalTime[formindex] * 60 * 60000);
    }

  }

  ChangeDate(formindex) {
    this.endDate[formindex] = new Date(this.startDate[formindex].getTime()
      + this.estimateTotalTime[formindex] * 60 * 60000);
    var mac = null;
    this.db.database.ref('Machine').child(this.selectedMachineList[formindex].machineNumber).once('value').then(async (DataSnapshot) => {
      mac = this.machine_controller.getMachine(DataSnapshot);
      if (mac) {
        if (this.dateFormat.validateSchedulingMachine(this.startDate[formindex], this.endDate[formindex], mac)) {
          this.toast.error('Crush with original schedule', 'Please select other time');
          this.formvalid[formindex] = false;
          return;
        }
        else {
          this.formvalid[formindex] = true;

        }
      }
    })
  }


  parFloat(value) {
    return parseFloat(value);
  }


  checkStock(value){
    const part = this.clone.find(p=>p.itemCode === value);
    if(part){
      this.toast.info("Stock quantity: " + (part.pieces? part.pieces: "0") ,'Item Code: ' +part.itemCode,{
        positionClass: 'toast-top-center'
     })
    }

  }

  tooltips(value){
    const part = this.clone.find(p=>p.itemCode === value);
    if(part){
      return "Stock quantity: " + part.pieces
    }
  }
}
