import { PartDB_controller } from './../../Services/DB_Controller/PartDB_controller';
import { async } from '@angular/core/testing';
import { RawDB_controller } from './../../Services/DB_Controller/RawDB_controller';
import { RawMaterialInfo } from './../../Services/Object_Classes/RawMaterial/RawMaterial';
import { SupplierDB_controller } from './../../Services/DB_Controller/SupplierDB_controller';
import { Supplier } from './../../Services/Object_Classes/RawMaterial/Supplier';
import { AngularFirestore } from '@angular/fire/firestore';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { MachineDB_controller } from './../../Services/DB_Controller/MachineDB_controller';
import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import Chart from 'chart.js';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgZone } from '@angular/core';
import { Machine, ScheduleTracker } from 'src/app/Services/Object_Classes/Machine/Machine';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PartServices } from 'src/app/Services/Utilities/part.service';

import * as XLSX from 'xlsx';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  InterruptCodeList = []
  machineDbControler: MachineDB_controller = new MachineDB_controller(this.db)
  machineList = [];
  jobOEE: number[] = [];
  monthOEE: number[] = [];
  noJobHours: number[] = [];
  partlist: Part[] = [];
  productionPerformance=[];
  constructor(
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private toast: ToastrService,
    private zone: NgZone,
    private partSrv: PartServices,
    private changeDef: ChangeDetectorRef
  ) { }
  ngOnInit() {
    this.setup();
    this.partSrv.currentPart.subscribe(part => this.partlist = part);
    setTimeout(() => {
      this.spinner.hide();
  }, 10000);

  }
  getTime(value) {
    return (new Date(new Date().getTime() + value * 60 * 60000))
  }
  customer: Customer[] = []
  supplier: Supplier[] = []
  rawmaterial: RawMaterialInfo[] = []

  async getPart(schedule: ScheduleTracker): Promise<void> {
    const result = this.partlist.find(e => e.Part_No === schedule.Machine_Schedule_Part_No);
    schedule.OpeningStockPart = result.Stock_Quantity;
    schedule.PartName = result.Part_Name;
    schedule.MouldRefNo = result.Mould_Reference_No;
    schedule.PartCavityNum = result.Number_Cavity.toString();
    if ((schedule.Exp_Qty - schedule.Acc_Qty) <= 0)
      schedule.HourLeft = 0;
    else
      schedule.HourLeft = parseFloat(schedule.PredefinedCycleTime) * ((schedule.Exp_Qty - schedule.Acc_Qty) / parseFloat(schedule.PartCavityNum)) / 3600;

  }


  setup() {
    this.spinner.show();
    this.machineList = [];
    this.firestore.collection('InterruptCodes').get().forEach(interruptCode => {
      if (!interruptCode.empty) {
        interruptCode.forEach(code => {
          this.InterruptCodeList.push(code.data());
        })
      }
    })
    
    this.firestore.collection('Machines').get().forEach(machine => {
      if (machine.size > 0) {
        machine.forEach(m => {
          const machine = { ...m.data(), schedules: [] };
          this.machineList.push(machine);
          this.firestore.collection('Machines').doc(m.id).collection('Schedule_Current').get().forEach(schedules => {
            schedules.forEach(s => {
              var schedule = { id: s.id, payload: s.data() };
              machine.schedules.push(schedule);
              this.firestore.collection('Machines').doc(m.id).collection('Schedule_Current').doc(s.id).snapshotChanges().forEach(snap => {
                var r = machine.schedules.find(e => e.id === snap.payload.id)
                r.payload = snap.payload.data();
              })
            })

            this.firestore.collection('Machines').doc(m.id).collection('Schedule_Queue', ref => ref.orderBy("startDate", "asc").limit(1)).get().forEach(schedules => {
              schedules.forEach(s => {
                var schedule = { id: s.id, payload: { ...s.data(), startDate: s.data().startDate.toDate(), endDate: s.data().endDate.toDate() } };
                machine.schedules.push(schedule);
              })
            })

          })
        })
      }
    })
    this.spinner.hide();
  }


  getColor(machine) {
    if (machine.schedules.length > 0) {
      if (machine.Status.match('ON') && machine.Machine_Code.match('-') && machine.schedules[0].payload.status === 'In Progress') {
        return 'running';
      }else if (machine.Status.match('ON') && machine.Machine_Code.match('-') && machine.schedules[0].payload.status === 'Stopped'){
        return 'warning blinkingBackground';
      }else{
        return 'off';
      }
    } else {
      if (machine.Status.match('ON') && machine.Machine_Code.match('-')) {
        return 'warning blinkingBackground';
      } else if (machine.Status.match('OFF') && machine.Machine_Code.match('-')) {
        return 'off';
      } else {
        return 'error';
      }
    }
  }



  Nan(value) {
    if (isNaN(value))
      return 0
    else
      return value
  }
  getCode(value) {
    return this.InterruptCodeList.find(i => i.value === value)?this.InterruptCodeList.find(i => i.value === value).viewValue: 'Others';
  }


  returnNumber(value) {
    return parseInt(value);
  }
//   file:any
//   arrayBuffer:any
//   filelist:any

//   addfile(event)
//   {
//   this.file= event.target.files[0];
//   let fileReader = new FileReader();
//   fileReader.readAsArrayBuffer(this.file);
//   fileReader.onload = (e) => {
//       this.arrayBuffer = fileReader.result;
//       var data = new Uint8Array(this.arrayBuffer);
//       var arr = new Array();
//       for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
//       var bstr = arr.join("");
//       var workbook = XLSX.read(bstr, {type:"binary"});
//       var first_sheet_name = workbook.SheetNames[0];
//       var worksheet = workbook.Sheets[first_sheet_name];
//       this.filelist = XLSX.utils.sheet_to_json(worksheet,{raw:true});

//       for (const list of this.filelist) {
//         console.log(list['Part No'])
//         console.log(new Date(list['Project Start Date 2']))
//         this.db.database.ref('Part').child(list['Part No']).update({
//           'Project Start Date' : new Date(list['Project Start Date 2'])
//         })

//       }

//   }
// }

compareDate(a, b, isAsc: boolean) {
  a = new Date(a);
  b = new Date(b);
  return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
}

getStatus(value) {
  if (value === 'ON')
    return 'On-Color'
  else
    return 'Off-Color'
}

CutString(value: string) {
  return value.length > 14 ? '<br>' : ''
}

getTotalRunTime(schedule) {
  if (schedule.status === 'Waiting')
    return 0;
  let startDate = new Date(schedule.startDate.toDate());
  let currentDate = new Date();
  var hours = Math.abs(currentDate.getTime() - startDate.getTime()) / 36e5;
  var int_part = Math.trunc(hours); 
  var min = Number((hours-int_part)) * 60

  return hours.toFixed(0) +" hr " + min.toFixed(0) + " min";
}

getEstTime(schedule){
  var hours = (schedule.cycleTime * (parseFloat(schedule.productionQuantity)-parseFloat(schedule.counter)))/ 3600;

  return new Date(new Date().getTime()
    + hours * 60 * 60000);

}

calculateActualQuantity(schedule) {
  return ((parseFloat(schedule.counter || 0) / 360) * 0.98425).toFixed(2)
}

calculateNumberOfWood(schedule) {
  return (((parseFloat(schedule.counter || 0) / 360) * 0.98425) / parseFloat(schedule.length)).toFixed(0)
}

calculateLength(schedule){
  return (parseFloat(schedule.counter) * parseFloat(schedule .length)).toFixed(2)
}

getProgress(schedule, number) {
  if(number === 'Machine 1')
    return (parseFloat(this.calculateActualQuantity(schedule)) / parseFloat(schedule.productionQuantity) * 100).toFixed(2);
  else if(number === 'Machine 2')
    return (parseFloat(this.calculateLength(schedule)) / parseFloat(schedule.productionQuantity) * 100).toFixed(2);
}


}
